import {PALETTE_IDS} from '#core/application/modules/ids.js';
import {COLOR, FONT} from '../theme.js';

export function getMarketsConfig() {
    return {
        palette: {
            [PALETTE_IDS.MARKETS]: {
                MARKETS_CONTAINER_HEADER_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
                MARKET_SELECTION_EACH_WAY_TERMS_LABEL_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontSize: FONT.SIZE_SMALL,
                },
                MARKETS_CONTAINER_HEADER_BET_BUILDER_ICON_COLOR: COLOR.BRAND.FOREGROUND1,
                MARKETS_SELECTION_HEADER_LABEL_TEXT: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                    color: COLOR.BRAND.FOREGROUND2,
                },
                MARKETS_HANDICAP_RANGE_INPUT: {
                    RANGE_LABEL_TEXT: {
                        fontWeight: FONT.WEIGHT_BOLD,
                    },
                    RANGE_TRACK_COLOR: COLOR.BRAND.FOREGROUND3,
                    RANGE_THUMB_COLOR: COLOR.BRAND.FOREGROUND1,
                },
            },
        },
    };
}
