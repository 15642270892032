import {PALETTE_IDS} from '#core/application/modules/ids.js';
import {COLOR, FONT} from '../theme.js';

export function getVirtualsConfig() {
    return {
        palette: {
            [PALETTE_IDS.VIRTUALS]: {
                VIRTUALS_EVENT_NAME_TEXT: COLOR.BRAND.FOREGROUND1,
                VIRTUALS_EVENT_SELECTOR_BG: COLOR.BRAND.BACKGROUND7,
                VIRTUALS_EVENT_SELECTOR_BOTTOM_DIVIDER: COLOR.BASE.PAGE,
                VIRTUALS_EVENT_SELECTOR: {
                    PIVOT_ROW_BG: COLOR.BRAND.BACKGROUND7,
                },
                VIRTUALS_STREAM_CONTAINER_BG: COLOR.BRAND.BACKGROUND7,
                VIRTUALS_NEXT_EVENT_COUNTDOWN_TEXT: COLOR.BRAND.ACCENT2,
                VIRTUALS_NEXT_EVENT_COUNTDOWN_BG: COLOR.BRAND.BACKGROUND5,
                VIRTUALS_MOBILE_RACE_DETAILS_TEXT: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                    color: COLOR.BRAND.FOREGROUND3,
                },
                MOBILE_SPORT_SELECTOR: {
                    PIVOT_ROW_BG: COLOR.BRAND.BACKGROUND1,
                },
            },
        },
    };
}
