import {PALETTE_IDS} from '#core/application/modules/ids.js';
import {isMobile} from '#core/utils/context-utils.js';
import {COLOR, FONT} from '../theme.js';

export function getSportsContentConfigs() {
    return {
        custom: {},
        palette: {
            [PALETTE_IDS.EVENT_LIST]: {
                EVENT_LIST_GROUP_MARKET_TEXT: {color: COLOR.BRAND.FOREGROUND2, fontWeight: 300},
                EVENT_LIST_HEADER_TEXT: {
                    color: isMobile() ? COLOR.BRAND.FOREGROUND2 : COLOR.BRAND.FOREGROUND1,
                    textTransform: 'uppercase',
                    fontWeight: isMobile() ? FONT.WEIGHT_SEMI_BOLD : FONT.WEIGHT_NORMAL,
                },
                EVENT_LIST_MARKETS_HEADER_TEXT: COLOR.BRAND.FOREGROUND1,
                EVENT_LIST_HEADER_BG: COLOR.BRAND.BACKGROUND3,
                EVENT_LIST_GROUP_HEADER_BG: COLOR.BRAND.BACKGROUND3,
                EVENT_LIST_MOBILE_HEADER_BG: COLOR.BRAND.BACKGROUND1,
                EVENT_LIST_ITEM_MORE_MARKETS_BUTTON_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
                EVENT_LIST_ITEM_MORE_MARKETS_BUTTON_ICON_COLOR: COLOR.BRAND.FOREGROUND2,
                EVENT_LIST_ITEM_OUTRIGHT_EVENT_NAME: {
                    fontSize: FONT.SIZE_MEDIUM,
                    color: COLOR.BRAND.FOREGROUND1,
                },
                EVENT_LIST_COMMON_ITEM_DETAILS: {
                    EVENT_DETAILS_MINI_SCOREBOARD: {
                        MINI_SCOREBOARD_SERVER_INDICATOR: COLOR.BRAND.ACCENT2,
                    },
                },
                EVENT_LIST_GROUP_CLASS_TEXT: {fontWeight: FONT.WEIGHT_NORMAL, color: COLOR.BRAND.FOREGROUND2},
            },
            [PALETTE_IDS.EVENT_PAGE]: {
                EVENT_PAGE_VISUALISATION_SELECTOR: {
                    TOGGLE_BUTTON_BORDER_COLOR: COLOR.BRAND.BACKGROUND8,
                },
            },
            [PALETTE_IDS.SPORTS_CONTENT]: {
                SPORT_TABS_PIVOT: {
                    PIVOT_ROW_BG: COLOR.BRAND.BACKGROUND1,
                },
            },
            [PALETTE_IDS.SPORT_PAGE]: {
                SPORT_HEADER_BG: COLOR.BRAND.BACKGROUND7,
                SPORT_HEADER_TEXT: COLOR.BRAND.FOREGROUND5,
                LEAGUES_ITEM_NAME_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
                EVENT_LIST_SKELETON: {
                    EVENT_LIST_HEADER_BG: COLOR.BRAND.BACKGROUND1,
                    EVENT_LIST_GROUP_HEADER_BG: COLOR.BRAND.BACKGROUND1,
                    EVENT_LIST_MOBILE_HEADER_BG: COLOR.BRAND.BACKGROUND1,
                },

                COMPETITIONS_PROMOTION_QUICK_LINKS: {
                    PROMOTION_QUICK_LINK: {
                        PRIMARY_LINK_TEXT: {color: COLOR.BRAND.FOREGROUND1, fontWeight: FONT.WEIGHT_NORMAL},
                    },
                },
            },
            [PALETTE_IDS.COUPONS]: {
                TOP_SPORTS_SKELETON_PART: {
                    SKELETON_COLOR: COLOR.BRAND.BACKGROUND5,
                },

                // price-boost
                PRICE_BOOST_COUPON_EVENT_TIME_TEXT: {color: COLOR.BRAND.FOREGROUND2, fontWeight: FONT.WEIGHT_NORMAL},
            },
            [PALETTE_IDS.HOME_PAGE]: {
                HOME_PAGE_TOP_SPORTS_CONTAINER_BG: COLOR.BRAND.BACKGROUND6,
                HOME_PAGE_TOP_SPORTS_SKELETON_PART: {
                    SKELETON_COLOR: COLOR.BRAND.BACKGROUND5,
                },
            },
        },
    };
}
