import {PALETTE_IDS} from '#core/application/modules/ids.js';
import {isMobile} from '#core/utils/context-utils.js';
import {COLOR, FONT} from '../theme.js';

export function getBettingCenterConfig() {
    return {
        palette: {
            [PALETTE_IDS.BET_RECEIPT]: {
                BET_RECEIPT_DOWNLOAD_PDF_BUTTON_ICON_COLOR: COLOR.BRAND.FOREGROUND7,
                BET_RECEIPT_BET_HEADER_TEXT: {
                    color: COLOR.BRAND.FOREGROUND1,
                    fontWeight: FONT.WEIGHT_BOLD,
                },
            },
            [PALETTE_IDS.BETTING_CENTER]: {
                BETTING_CENTER_BET_CONTAINER_BG: COLOR.BRAND.BACKGROUND1,
            },
            [PALETTE_IDS.BETSLIP]: {
                BETSLIP_CONTAINER_BG: isMobile() ? COLOR.BASE.PAGE : COLOR.BRAND.BACKGROUND1,
                BETSLIP_BET_POTENTIAL_RETURNS_TEXT: COLOR.BRAND.FOREGROUND2,
                BETSLIP_BET_POTENTIAL_TAX_TEXT: COLOR.BRAND.FOREGROUND2,
                BETSLIP_REMOVE_ALL_BUTTON: {
                    TERTIARY_BUTTON_TEXT: COLOR.BRAND.FOREGROUND2,
                },

                BETSLIP_REMOVE_BET_BUTTON: {
                    CLOSE_BUTTON_ICON_COLOR: COLOR.BRAND.FOREGROUND2,
                },

                BETSLIP_BB_BET_ICON_COLOR: COLOR.BRAND.CTA1,

                BETSLIP_MULTIPLE_BETS_HEADER_TEXT: COLOR.BRAND.FOREGROUND2,

                BETSLIP_FREE_BETS_HEADER_TEXT: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                    color: COLOR.BRAND.FOREGROUND2,
                },
                BETSLIP_ERROR_MESSAGE_TEXT: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                    color: COLOR.BRAND.FOREGROUND1,
                },
                BETSLIP_SETTINGS_BUTTON_DOT_NOTIFICATION: {
                    DOT_COLOR: COLOR.BRAND.ACCENT1,
                },
                // Sliding betslip
                SLIDING_BETSLIP_BUTTON_HIGHLIGHTED_BG: COLOR.BRAND.CTA1,
                SLIDING_BETSLIP_BUTTON_HIGHLIGHTED_TEXT: COLOR.BRAND.FOREGROUND7,
            },
            [PALETTE_IDS.NUMERIC_KEYPAD]: {
                KEYPAD_INCREASE_BUTTON_BG: COLOR.BRAND.BACKGROUND5,
                KEYPAD_CONTAINER_BG: COLOR.BRAND.BACKGROUND5,
                KEYPAD_COMPACT_CONTAINER_BG: COLOR.BRAND.BACKGROUND6,
                KEYPAD_BUTTON: {
                    TOGGLE_BUTTON_ICON_COLOR: COLOR.BRAND.FOREGROUND2,
                },
            },
            [PALETTE_IDS.YOUR_BETS]: {
                YOUR_BETS_CONTAINER_BG: isMobile() ? COLOR.BASE.PAGE : COLOR.BRAND.BACKGROUND1,
                OPEN_BET_LIVE_EVENT_TIMING_STATUS: {
                    EVENT_TIMING_LIVE_TEXT: {
                        color: COLOR.BRAND.FOREGROUND1,
                        fontWeight: FONT.WEIGHT_SEMI_BOLD,
                    },
                },
                OPEN_BET_RESULT_TEXT: COLOR.BRAND.FOREGROUND1,
                OPEN_BET_BASE_INFO: {
                    BET_EVENT_NAME_TEXT: {
                        fontWeight: FONT.WEIGHT_NORMAL,
                        color: COLOR.BRAND.FOREGROUND2,
                        fontSize: FONT.SIZE_XSMALL,
                    },
                },
                OPEN_BET_CASH_OUT_BUTTON: {
                    PRIMARY_BUTTON_DISABLED_BG: COLOR.BRAND.FOREGROUND2,
                    PRIMARY_BUTTON_DISABLED_TEXT: COLOR.BRAND.BACKGROUND2,
                },
                YOUR_BETS_EMPTY_CONTENT_MESSAGE: {
                    EMPTY_CONTENT_MESSAGE_TEXT: {
                        fontWeight: FONT.WEIGHT_NORMAL,
                        color: COLOR.BRAND.FOREGROUND2,
                    },
                },
                OPEN_BET_CASH_OUT_SETTINGS_BUTTON_DOT_NOTIFICATION: {
                    DOT_COLOR: COLOR.BRAND.CTA1,
                },
            },
            [PALETTE_IDS.OVERASK]: {
                OVERASK_REVIEWING_TEXT: COLOR.BRAND.FOREGROUND2,
                OVERASK_BET_OFFER_HEADER_TEXT: {
                    fontWeight: FONT.WEIGHT_SEMI_BOLD,
                    color: COLOR.BRAND.FOREGROUND2,
                },
                OVERASK_TRADER_BET_OFFER_CONTAINER_BG: COLOR.BRAND.BACKGROUND5,
                OVERASK_BET_OFFER_HEADER_CHECK_BUTTON_BG: COLOR.BRAND.FOREGROUND1,
                OVERASK_BET_OFFER_HEADER_CHECK_BUTTON_BORDER_COLOR: COLOR.BRAND.FOREGROUND1,
                OVERASK_BET_OFFER_HEADER_CHECK_BUTTON_ICON_COLOR: COLOR.BRAND.FOREGROUND7,

                OVERASK_BET_OFFER_STATUS_TITLE_TEXT: {
                    fontSize: FONT.SIZE_LARGE,
                    color: COLOR.BRAND.FOREGROUND1,
                },
                OVERASK_BET_OFFER_BASE_INFO: {
                    BET_EVENT_NAME_TEXT: {
                        fontWeight: FONT.WEIGHT_NORMAL,
                        color: COLOR.BRAND.FOREGROUND2,
                    },
                    BET_MARKET_CVI: {
                        CVI_NEW_VALUE_TEXT: COLOR.BRAND.FOREGROUND7,
                    },
                },
                OVERASK_BET_OFFER_STAKE_PER_LINE_TEXT: {
                    fontWeight: FONT.WEIGHT_NORMAL,
                    color: COLOR.BRAND.FOREGROUND2,
                },
            },
        },
    };
}
