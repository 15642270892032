import {PALETTE_IDS} from '#core/application/modules/ids.js';
import {COLOR, FONT} from '../theme.js';

export function getMatchAccaConfig() {
    return {
        palette: {
            [PALETTE_IDS.MATCH_ACCA]: {
                MATCH_ACCA_SELECTIONS_BAR_BORDER_COLOR: COLOR.BRAND.FOREGROUND5,
                MATCH_ACCA_SELECTIONS_BAR_ODDS_LABEL_TEXT: {
                    color: COLOR.BRAND.FOREGROUND7,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
            },
            [PALETTE_IDS.MARKET_INSTRUCTIONS]: {
                MARKET_INSTRUCTIONS_CONTENT_BG: COLOR.BASE.PAGE,
                MARKET_INSTRUCTIONS_BET_BUILDER_ICON_COLOR: COLOR.BRAND.CTA1,
                MARKET_INSTRUCTIONS_CONTAINER_BG: COLOR.BRAND.BACKGROUND1,
                MARKET_INSTRUCTIONS_CONTENT_TEXT: {color: COLOR.BRAND.FOREGROUND1, fontWeight: FONT.WEIGHT_NORMAL},
            },
            [PALETTE_IDS.MATCH_ACCA_SLIP]: {
                MATCH_ACCA_SLIP_ADD_TO_BETSLIP_BORDER_COLOR: COLOR.BRAND.ACCENT1,
                MATCH_ACCA_SLIP_SELECTION_SEPARATOR_ICON_COLOR: COLOR.BRAND.FOREGROUND2,
                MATCH_ACCA_SLIP_ONBOARDING_SELECTION_SEPARATOR_ICON_COLOR: COLOR.BRAND.FOREGROUND3,
                MATCH_ACCA_SLIP_BG: COLOR.BASE.PAGE,
                MATCH_ACCA_SLIP_HEADER_EVENT_NAME_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
                MATCH_ACCA_SLIP_HEADER_ODDS_LABEL_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
                MATCH_ACCA_SLIP_HEADER_ODDS_VALUE_TEXT: COLOR.BRAND.FOREGROUND1,
                MATCH_ACCA_SLIP_ADD_TO_BETSLIP_ODDS_CONTAINER_BG: 'transparent',
                MATCH_ACCA_SLIP_ADD_TO_BETSLIP_ODDS_LABEL_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontWeight: FONT.WEIGHT_NORMAL,
                },
                MATCH_ACCA_SLIP_SELECTION_BG: COLOR.BRAND.BACKGROUND1,
                MATCH_ACCA_SLIP_ONBOARDING_SELECTION_EVENT_NAME_TEXT: {fontWeight: FONT.WEIGHT_SEMI_BOLD},
                MATCH_ACCA_SLIP_ONBOARDING_SELECTION_NAME_TEXT: {
                    color: COLOR.BRAND.FOREGROUND2,
                    fontWeight: FONT.WEIGHT_SEMI_BOLD,
                },
                MATCH_ACCA_SLIP_SELECTION_BASE_INFO: {
                    BET_SUSPENDED_NOTIFICATION: {
                        WARNING_TEXT: COLOR.BRAND.FOREGROUND7,
                    },
                },
                MATCH_ACCA_SLIP_ERROR_NOTIFICATION: {
                    ERROR_TEXT: {
                        fontWeight: FONT.WEIGHT_NORMAL,
                        color: COLOR.RESERVED.BLACK,
                    },
                },
            },
        },
    };
}
